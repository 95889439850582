import React from "react"
import KdpHeader from "../../blocs/Header/Header"
import KdpFooter from "../../blocs/Footer/Footer"

export default function Index() {
  return (
    <div className="bg-white">
      <header>
        <KdpHeader />
      </header>
      <main>
          
      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}

